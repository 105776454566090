import React, { useState, useCallback } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';

import { toast } from 'react-toastify';
import { Translate } from 'react-localize-redux';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import BG from '../../../Styles/images/backgrounds/login-bg.jpg';
import { changeLanguage, loginUser } from '../../../Action/index';
import LoadingScreen from '../../../Components/LoadingScreen';

const ValidationSchema = Yup.object().shape({
  username: Yup.string().required('username'),
  password: Yup.string().required('password'),
});

const mapDispatchToProps = {
  loginDispatch: loginUser,
  changeLang: changeLanguage,
};

export default connect(
  null,
  mapDispatchToProps,
)(({ loginDispatch, loginUser, loginFacebook, loginGoogle, changeLang }) => {
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    onSubmit: ({ username, password }) => {
      setLoading(true);
      loginUser({
        variables: {
          username,
          password,
        },
      })
        .then(async ({ data: { loginUser } }) => {
          if (loginUser) {
            await loginDispatch(loginUser);
            setLoading(false);
            changeLang(loginUser.account.lang);
            await localStorage.setItem('token', loginUser.account.token);
            setTimeout(() => (window.location.href = '/'), 20);
          } else {
            toast.error(<Translate id="UserNotFound" />);
          }
        })
        .catch(error => {
          setLoading(false);
          error.graphQLErrors.length > 0
            ? toast.error(
                error.graphQLErrors[0].message === 'Wrong Password' ? (
                  <Translate id="WrongPasswordOrEmail" />
                ) : (
                  <Translate id="somethingWentWrong" />
                ),
              )
            : toast.error(<Translate id="somethingWentWrong" />);
        });
    },
    validationSchema: ValidationSchema,
  });

  const { errors, touched, values, handleChange, handleSubmit } = formik;

  const responseFacebook = useCallback(
    async response => {
      await loginFacebook({
        variables: {
          id: response.id,
        },
      })
        .then(async ({ data: { loginUser } }) => {
          if (loginUser) {
            await loginDispatch(loginUser);
            changeLang(loginUser.account.lang);
            await localStorage.setItem('token', loginUser.account.token);
            window.location.href = '/';
          }
        })
        .catch(error =>
          error.graphQLErrors.length > 0
            ? toast.error(
                error.graphQLErrors[0].message === 'Wrong Password' ? (
                  <Translate id="WrongPasswordOrEmail" />
                ) : (
                  <Translate id="somethingWentWrong" />
                ),
              )
            : toast.error(<Translate id="somethingWentWrong" />),
        );
    },
    [loginFacebook, loginDispatch, changeLang],
  );

  const responseGoogle = async response => {
    await loginGoogle({
      variables: {
        id: response.profileObj.googleId,
      },
    })
      .then(async ({ data: { loginUser } }) => {
        if (loginUser) {
          loginDispatch(loginUser);
          changeLang(loginUser.account.lang);
          await localStorage.setItem('token', loginUser.account.token);

          window.location.href = '/';
        }
      })
      .catch(error =>
        error.graphQLErrors.length > 0
          ? toast.error(
              error.graphQLErrors[0].message === 'Wrong Password' ? (
                <Translate id="WrongPasswordOrEmail" />
              ) : (
                <Translate id="somethingWentWrong" />
              ),
            )
          : toast.error(<Translate id="somethingWentWrong" />),
      );
  };

  return (
    <div className="page-wrapper login">
      <main className="main">
        {loading && <LoadingScreen />}

        <nav aria-label="breadcrumb" className="breadcrumb-nav border-0 mb-0">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/">
                  <Translate id="home" />
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <Translate id="Login" />
              </li>
            </ol>
          </div>
        </nav>

        <div
          className="login-page bg-image pt-8 pb-8 pt-md-12 pb-md-12 pt-lg-17 pb-lg-17"
          style={{ backgroundImage: `url(${BG})` }}>
          <div className="container">
            <div className="form-box">
              <div className="form-tab">
                <ul className="nav nav-pills nav-fill" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="signin-tab-2"
                      data-toggle="tab"
                      href="#signin-2"
                      role="tab"
                      aria-controls="signin-2"
                      aria-selected="false">
                      <Translate id="Login" />
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    className="tab-pane  fade show active in"
                    id="signin-2"
                    role="tabpanel"
                    aria-labelledby="signin-tab-2">
                    <form onSubmit={handleSubmit}>
                      <div
                        className={
                          errors.username && touched.username
                            ? 'form-group error'
                            : 'form-group'
                        }>
                        <label htmlFor="username">
                          <Translate id="Email address or phone" />*
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="username"
                          name="username"
                          onChange={handleChange}
                          value={values.username}
                          required
                        />
                      </div>

                      <div
                        className={
                          errors.password && touched.password
                            ? 'form-group error'
                            : 'form-group'
                        }>
                        <label htmlFor="password">
                          <Translate id="password" /> *
                        </label>
                        <input
                          type="password"
                          className={'form-control'}
                          id="password"
                          name="password"
                          onChange={handleChange}
                          value={values.password}
                          required
                        />
                      </div>

                      <div className="form-footer">
                        <button
                          type="submit"
                          className="btn btn-outline-primary-2">
                          <span>
                            <Translate id="Login" />
                          </span>
                          {loading ? (
                            <i className="fa fa-spinner fa-pulse" />
                          ) : (
                            <i className="icon-long-arrow-right"></i>
                          )}
                        </button>

                        <div className="forget-link-content">
                          <a href="/forget-password" className="forgot-link">
                            <Translate id="Forgot Your Password" />
                          </a>
                        </div>
                      </div>
                      <div
                        className="row"
                        style={{
                          justifyContent: 'center',
                          borderBottom: '0.1rem solid #ebebeb',
                        }}>
                        <p className="text-center">
                          <Translate id="Not a member" />
                        </p>
                        <a
                          href="/signup"
                          style={{ color: '#c96', marginLeft: '5px' }}>
                          <Translate id="create account" />
                        </a>
                      </div>
                    </form>
                    <div className="form-choice">
                      <p className="text-center">
                        <Translate id="or sign in with" />
                      </p>
                      <div className="row">
                        <div className="col-sm-6">
                          <GoogleLogin
                            clientId="42282094691-pu17lekjgvcc1426g0ggrs9doln7gpe4.apps.googleusercontent.com"
                            buttonText="LOGIN WITH GOOGLE"
                            render={renderProps => (
                              <div
                                onClick={() => renderProps.onClick()}
                                className="btn btn-login btn-g">
                                <i className="icon-google"></i>
                                <Translate id="Login With Google" />
                              </div>
                            )}
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                          />
                        </div>
                        <div className="col-sm-6">
                          <FacebookLogin
                            appId="134773307824627"
                            callback={response => responseFacebook(response)}
                            fields="email,picture, first_name, last_name, local_news_megaphone_dismiss_status"
                            render={renderProps => (
                              <div
                                onClick={() => renderProps.onClick()}
                                className="btn btn-login  btn-f">
                                <i className="icon-facebook-f" />
                                <Translate id="Login With Facebook" />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
});
