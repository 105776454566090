export const GQLURI = 'https://api.layls.com/api';
export const PUBLICURI = 'https://api.layls.com/public';

export const subscribeUrl = `${PUBLICURI}email/subscribe`;
export const contactusUrl = `${PUBLICURI}email/contactus`;
export const uploadUrl = `${PUBLICURI}upload/image`;
export const createCart = `${PUBLICURI}cart/create`;
export const removeCart = `${PUBLICURI}cart/remove`;
export const deleteCart = `${PUBLICURI}cart/delete`;
export const clearCart = `${PUBLICURI}cart/clear`;
export const createWishlist = `${PUBLICURI}wishlist/create`;
export const findUserCart = `${PUBLICURI}cart/findUserCart`;
