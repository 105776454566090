/* eslint-disable react/prop-types */
import React, { Component, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import * as moment from 'moment';
import 'moment/locale/ar';
import {
  addCategory,
  queryCartItems,
  productLength,
  getNotifications,
  emptyCart,
  loginUser,
  changeCurrency,
} from '../Action/index';

import globalTranslations from '../i18n/global.json';
import Home from '../Containers/Home/Home';
import NotFound from '../Containers/NotFound/NotFound';
import CommingSoon from '../Containers/CommingSoon/CommingSoon';
import Product from '../Containers/Product/Product';
import CheckBoxPage from '../Containers/Checkout/Checkout';
import CartPage from '../Containers/Cart/Cart';
import WishlistPage from '../Containers/Wishlist/Wishlist';
import Login from '../Containers/Login/Login';
import Signup from '../Containers/Signup/Signup';
import NewProduct from '../Containers/NewProduct/NewProduct';
import AllProducts from '../Containers/AllProducts/AllProducts';
import ContactUs from '../Containers/ContactUs/ContactUs';
import AboutUs from '../Containers/AboutUs/AboutUs';
import FAQ from '../Containers/FAQ/FAQ';
import Profile from '../Containers/Profile/Profile';
import Notifications from '../Containers/Notifications/Notifications.js';
import ThankYou from '../Containers/Checkout/Components/ThankYou.js';
import Cashback from '../Containers/Cashback/Cashback.js';
import PivacyPolice from '../Components/Static/PivacyPolice';
import ReturnPolice from '../Components/Static/ReturnPolice';
import { useQuery } from 'react-apollo';
import { ADDWISHLIST } from '../Containers/Wishlist/Wishlist-gql';
import ForgetPassword from '../Containers/ForgetPassword/ForgetPassword';
import ConfirmPassword from '../Containers/ForgetPassword/ConfirmPassword';
import { GET_CATEGORIES, GET_CART, USER_DETAILS } from './Router-gql';
import EditProduct from '../Containers/EditProduct/EditProduct';
import Search from '../Containers/Search/Search';
import Verification from '../Containers/Verification/Verification';
import { USER_NOTIFICATION } from '../Containers/Notifications/Notifications-gql';

const SSLDetails = () =>
  '1D40E096C97B7895D6FC83B84C3FE81B4C1D6F90B76F4DE06F89B5AA5EE6F85D comodoca.com 5e6152819af99';

const RouterContainer = props => {
  const {
    addCateg,
    queryCartItem,
    addProductLength,
    saveNotifications,
    cart,
    removeFromCart,
    userLoggedIn,
    // changeCurrencys,
    account: { user, currency },
  } = props;
  const { data } = useQuery(ADDWISHLIST, {
    variables: {
      product: props.wishlist.wishListItems,
    },
    skip: !props?.account?.user?.id,
  });

  useQuery(GET_CART, {
    skip: props?.account?.user?.id ? false : true,
    variables: {
      user: props?.account?.user?.id,
    },
    onCompleted: cartData => {
      queryCartItem({
        currency,
        ...cartData?.findCartByUser?.cart,
      });
      addProductLength(cartData?.findCartByUser?.productLength);
    },
  });

  useQuery(USER_DETAILS, {
    skip: props?.account?.user?.id ? false : true,
    variables: {
      id: props?.account?.user?.id,
    },
    onCompleted: userData => {
      if (userData && userData.userById) {
        userLoggedIn(userData.userById);
      }
    },
  });

  useQuery(USER_NOTIFICATION, {
    skip: props?.account?.user?.id ? false : true,
    variables: {
      id: props?.account?.user?.id,
    },
    onCompleted: notificationData => {
      const notificationNotRead = notificationData?.userNotifications.filter(
        item => !item.notification.read,
      ).length;
      saveNotifications(
        notificationData?.userNotifications,
        notificationNotRead,
      );
    },
  });

  useQuery(GET_CATEGORIES, {
    onCompleted: categoriesData => addCateg(categoriesData.categories),
  });

  useEffect(() => {
    const now = Date.now();
    if (moment(cart.timer).isBefore(now)) {
      removeFromCart(user.id);
    }
    // const getCurrency = async () => {
    //   fetch('http://ip-api.com/json/?fields=country,currency')
    //     .then(res => res.json())
    //     .then(({ currency: remoteCurrency, country }) => {
    //       remoteCurrency = remoteCurrency.toLowerCase();
    //       changeCurrencys({
    //         value: remoteCurrency.toLowerCase(),
    //         label: country,
    //       });
    //       return;
    //     })
    //     .catch(err => console.log(err));
    // };
    // getCurrency();
    // eslint-disable-next-line
  }, []);

  return <Routers {...props} {...data} />;
};

class Routers extends Component {
  constructor(props) {
    super(props);
    props.initialize({
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Arabic', code: 'ar' },
      ],
      translation: globalTranslations,
      options: { renderToStaticMarkup },
    });

    const currentLang = props.account.language;

    document.documentElement.lang = props.account.language;
    document.body.classList.add(
      props.account.language === 'en' ? 'ltr' : 'rtl',
    );
    document.body.setAttribute(
      'dir',
      props.account.language === 'en' ? 'ltr' : 'rtl',
    );

    moment.locale(currentLang);
    props.setActiveLanguage(currentLang);
  }

  render() {
    const {
      configs: { loggedin },
    } = this.props;
    return (
      <Router>
        <Switch>
          <Route
            exact
            path="/"
            render={props => <Home {...this.props} {...props} />}
          />

          <Route
            exact
            path="/coming-soon"
            render={props => <CommingSoon {...this.props} {...props} />}
          />

          <Route
            exact
            path="/checkout"
            render={props =>
              loggedin ? (
                <CheckBoxPage {...this.props} {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />

          <Route
            exact
            path="/thankyou"
            render={props =>
              loggedin ? (
                <ThankYou {...this.props} {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />

          <Route
            exact
            path="/cart"
            render={props => <CartPage {...this.props} {...props} />}
          />

          <Route
            exact
            path="/product/:id"
            render={props => <Product {...this.props} {...props} />}
          />

          <Route
            exact
            path="/wishlist"
            render={props => <WishlistPage {...this.props} {...props} />}
          />

          <Route
            exact
            path="/login"
            render={props => <Login {...this.props} {...props} />}
          />

          <Route
            exact
            path="/signup"
            render={props => <Signup {...this.props} {...props} />}
          />

          <Route
            exact
            path="/signup/verification"
            render={props => <Verification {...this.props} {...props} />}
          />

          <Route
            exact
            path="/new-product"
            render={props =>
              loggedin ? (
                <NewProduct {...this.props} {...props} />
              ) : (
                <Redirect to="/login" />
              )
            }
          />

          <Route
            exact
            path="/all-product"
            render={props => <AllProducts {...this.props} {...props} />}
          />

          <Route
            exact
            path="/contact-us"
            render={props => <ContactUs {...this.props} {...props} />}
          />

          <Route
            exact
            path="/about-us"
            render={props => <AboutUs {...this.props} {...props} />}
          />

          <Route
            exact
            path="/faq"
            render={props => <FAQ {...this.props} {...props} />}
          />

          <Route
            exact
            path="/profile"
            render={props =>
              loggedin ? (
                <Profile {...this.props} {...props} />
              ) : (
                <Redirect to="/" />
              )
            }
          />

          <Route
            exact
            path="/notification"
            render={props => <Notifications {...this.props} {...props} />}
          />

          <Route
            exact
            path="/cash-back"
            render={props => <Cashback {...this.props} {...props} />}
          />

          <Route
            exact
            path="/privacy-policy"
            render={props => <PivacyPolice {...this.props} {...props} />}
          />

          <Route
            exact
            path="/return-policy"
            render={props => <ReturnPolice {...this.props} {...props} />}
          />

          <Route
            exact
            path="/forget-password"
            render={props => <ForgetPassword {...this.props} {...props} />}
          />

          <Route
            exact
            path="/confirm-password/:token"
            render={props => <ConfirmPassword {...this.props} {...props} />}
          />

          <Route
            exact
            path="/edit-product/:id"
            render={props => <EditProduct {...this.props} {...props} />}
          />

          <Route
            exact
            path="/search/:word"
            render={props => <Search {...this.props} {...props} />}
          />

          <Route
            exact
            path="/.well-known/pki-validation/A023404FF9321345CFFB4F96108B66BE.txt"
            render={props => <SSLDetails {...this.prop} {...props} />}
          />

          <Route component={NotFound} />
        </Switch>
        <ToastContainer autoClose={8000} position="top-center" />
      </Router>
    );
  }
}

const mapToProps = state => ({
  account: state.Account,
  configs: state.Configs,
  adminConfigs: state.AdminConfigs,
  wishlist: state.Wishlist,
  cart: state.Cart,
});

const mapDispatchToProps = {
  addCateg: addCategory,
  queryCartItem: queryCartItems,
  addProductLength: productLength,
  saveNotifications: getNotifications,
  removeFromCart: emptyCart,
  userLoggedIn: loginUser,
  changeCurrencys: changeCurrency,
};

export default connect(
  mapToProps,
  mapDispatchToProps,
)(withLocalize(RouterContainer));
